<template>
    <section class="row justify-content-center calendar-add-dias">
        <div class="row">
            <span class="ucfirst mb-4 f-600 f-18 text-general">{{mes_inicio | helper-fecha('MMMM Y')}} a </span><span class="ucfirst mb-4 mx-1 f-600 f-18 text-general">{{mes_final | helper-fecha('MMMM Y')}}</span>
        </div>
        <div class="row">
            <template v-for="(mes,i) in data_mes">
                <div :key="i" class="meses">
                    <div class="mes-nombre">
                        <p class="px-2 mb-2">{{mes.nombre}}</p>
                    </div>
                    <div class="dias">
                        <div v-for="(dia,k) in data_dias" :key="`${i}-${k}`" class="letra-dia mb-1">
                            <p>{{dia}}</p>
                        </div>
                        <div v-for="(dia2,m) in obtener_dias(mes)" :key="m" class="numd">
                            <el-popover placement="bottom" width="330" trigger="click">
                                <!--  -->
                                <div v-if="FormularioGrupo.length > 0 " class="row px-1">
                                    <div class="col-12 bg-smoke5 border-bottom border-top my-auto my-1">
                                        <p class="text-muted2 f-14 py-2">{{mes.nombre}}. {{dia2.id}}</p>
                                    </div>
                                    <div class="col-12 mt-2">
                                        <p v-for="(data,index) in FormularioGrupo" :key="index" class="text-white f-10 px-2 py-1 br-2 my-2" :style="`background-color:${data.color}`" @click="MostrarPopoverGrup(data.id)">
                                            {{_.get(data,'titulo','N/A')}}
                                        </p>
                                    </div>
                                </div>
                                <div v-else>
                                    <span>No hay Garantias</span>
                                </div>

                                <p :id="`popover-${i}-${dia2.id}`" slot="reference" :class="`badge-pill px-0 mb-0 ${MostrarDias(dia2.id,mes)}` " style="cursor:pointer;" @click="MostrarPopoverSimple(dia2.id,mes)">{{dia2.id}}</p>
                            </el-popover>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <modal-detalle-tarea ref="modalDetalleTarea" />
    </section>
</template>

<script>

/*
| Hecho por: Edwin ferreira el flaco hp
| Refactorizado por: Carlos sanchez
| 2017-2019
*/
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
    name: 'CalendarioPorMes',
    props: {
        opciones: {
            type: Array,
            required: false
        },
        popover: {
            type: Boolean,
            default: false
        },
        ano: {
            default: new Date().getFullYear()
        }
    },
    components: {
        modalDetalleTarea: () => import('./partials/modalDetalleTarea.vue'),
    },
    data(){
        return {
            varios: false,
            show: false,
            seleccionado: {},
            data_dias: {
                1:'D',
                2:'L',
                3:'M',
                4:'M',
                5:'J',
                6:'V',
                7:'S'
            },
            mes_inicio: moment().subtract(11, 'M'),
            mes_final: moment(),
            visible: false
        }
    },
    computed: {
        ...mapGetters({
            CalendarioList: 'Oportunidades/Bitacora/CalendarioList',
            FormularioGrupo: 'Oportunidades/Bitacora/FormularioGrupo',
        }),
        ano_actual(){
            return moment().year()
        },
        data_mes(){
            var primero = moment().subtract(11, 'M')
            var ultimo = moment()
            var array = []
            // console.log('primero  y ultimo',primero,ultimo);
            while (primero <= ultimo){
                array.push({
                    mes: primero.format('MM'),
                    nombre: primero.format('MMMM'),
                    ano: primero.format('Y')
                })
                primero = primero.add(1, 'M')
            }
            return _.orderBy(array, ['ano'], ['asc'])
        }
    },
    methods: {
        editarPeriodoDedicado(id){
            this.$refs.editarPeriodoDedicado.toggle(id)
        },
        verHistoriales(id){
            this.$refs.verHistoriales.toggle(id)
        },
        /**
        * [MostrarDias juan gomez 23 de octubre]
        * @param {[type]} dia []
        * @param {[type]} mes []
        */
        MostrarDias(dia, mes){
            let fecha = moment(`${mes.ano}-${mes.mes}-${dia}`, 'Y-MM-DD').format('Y-MM-DD')
            let no_planificada = this.CalendarioList.some(a => a.b_fecha === fecha && a.pt_fecha === null)
            if (no_planificada){
                return 'bg-primary text-light'
            }
            let planificada = this.CalendarioList.some(a => a.b_fecha === fecha && a.pt_fecha !== null)
            if (planificada){
                return 'bg-success text-light'
            }
        },
        /**
        * [MostrarPopoverSimple juan gomez 23 de octubre]
        * @param {[type]} dia [description]
        * @param {[type]} mes [description]
        */
        MostrarPopoverSimple(dia, mes){
            let fecha = moment(`${mes.ano}-${mes.mes}-${dia}`, 'Y-MM-DD').format('Y-MM-DD')
            let formulario = _.filter(this.CalendarioList, o => { return o.b_fecha === fecha })
            if (formulario.length > 0){
                this.$store.commit('Oportunidades/Bitacora/MutationFormularioGrupo',formulario)
            } else {
                this.$store.commit('Oportunidades/Bitacora/MutationFormularioBorrar')
            }
        },
        /**
        * [MostrarPopoverGrup juan gomez 23 de oct]
        * @param {[type]} id [description]
        */
        MostrarPopoverGrup(id){
            this.$refs.detalleTarea.toggle(parseInt(id))
            $('#detalleTarea').trigger('click')    
        },
        // lo demas parece ser mugre
        validar_dia(dia, mes){
            let tmp_fecha = moment(`${this.ano}-${mes}-${dia}`, 'Y-MM-DD').format('Y-MM-DD')
            return this.opciones.some(a => a.fecha === tmp_fecha)
        },
        aux_dia(e, dato, mes){
            var dia = dato.id
            mes = parseInt(mes ? mes: 0)
            let target = e
            let ano = this.ano_actual
            let fecha = moment(`${ano}-${mes}-${dia}`, 'Y-MM-DD')
            let fecha_seleccionada = null
            if (moment(fecha, 'Y-MM-DD').isValid()){
                fecha_seleccionada = fecha.format('Y-MM-DD')
            }

            let sel = { target, dia, mes, ano, fecha, fecha_seleccionada }
            this.seleccionado = sel
            this.$emit('fecha:seleccionada', sel)
            // this.$root.$emit('bv::show::popover', 'popover-' + mes + '-' + dia );
            this.varios =! this.varios
        },
        obtener_dias(tmp_mes){
            let that = this
            var diaSemana = moment(`${tmp_mes.ano}-${tmp_mes.mes}-1`,'YYYY-MM-DD').format('e')
            var ultimodia = moment(`${tmp_mes.ano}-${tmp_mes.mes}`,'YYYY-MM').daysInMonth()
            var mes = moment(`${tmp_mes.ano}-${tmp_mes.mes}`,'YYYY-MM').format('MMMM')

            let tmp = []
            switch (parseInt(diaSemana)){
                case 5:{
                    for (var k = 1; k <= 6 ; k++){
                        tmp.push('')
                    }
                }
                    break;
                case 4:{
                    for (var k = 1; k <= 5 ; k++){
                        tmp.push('')
                    }
                }
                    break;
                case 3:{
                    for (var k = 1; k <= 4 ; k++){
                        tmp.push('')
                    }
                }
                    break;
                case 2:{
                    for (var k = 1; k <= 3 ; k++){
                        tmp.push('')
                    }
                }
                    break;
                case 1:{
                    for (var k = 1; k <= 2 ; k++){
                        tmp.push('')
                    }
                }
                    break;
                case 0:{
                    for (var k = 1; k <= 1 ; k++){
                        tmp.push('')
                    }
                }
                    break

                default:
            }

            for (var i = 1; i <= ultimodia ; i++){
                tmp.push({id:i,show:false})
            }
            return tmp
        },
        abrirElModalFeo(variable,id,mes){
            this.visible = variable
            this.MostrarPopoverSimple(id,mes)
        }
    }
}
</script>

<style lang="scss" scoped>
.calendar-add-dias{
    .meses{
        max-width: 240px !important;
        .mes-nombre {
            width: 100% !important;
            font-size: 12px;
            font-weight: 700;
        }
        .dias {
            float: left;
            width: 80%;
            max-width: 245px !important;
            .letra-dia {
                float: left;
                font-size: 12px;
                width: 14.2%;
                text-align: center;
                color: var(--text-dark);
                max-width: 35px !important;
                font-weight: 600;
            }
            .numd {
                font-size: 12px;
                color: var(--text-dark);
                float: left;
                width: 14.2%;
                text-align: center;
                min-height: 30px;
                position: relative;
                max-width: 35px !important;
            }
        }
    }
}
.popo{
    z-index:999;
    position:absolute;
    height:50px;
    width:50px;
}

.ano {
    position: absolute;
    left: 0px;
    transform: translateY(-50%) !important;
    top: 50% !important;
}

.dia_actual{
    background: #0090ff;
    color: var(--general-text-color);
    padding: 2px;
    border-radius: 50%;
}
.dia_sel{
    position: absolute;
    height: 25px;
    width: 25px;
    background: gray;
    color: var(--general-text-color);
    padding: 2px;
    border-radius: 50%;
}

.valdia p {
    font-size: 10px;
    font-weight: 300;
}
.valdia {
    position: absolute;
    background: var(--general-text-color);
border-radius: 50%;
    left: 34%;
    top: 0px;
    min-width: 16px;
}
.diaConAlertas{
    background: #0090ff;
    color: var(--general-text-color);
    border-radius: 50%;
    cursor: pointer;
}
.diaSemana{
    background-color: #0090FF;
    border-radius: 50%;
    color: var(--general-text-color);
    padding-left: 3px;
    padding-right: 3px;
}
.btn-editar-garantia-alerta{

}
.btn-ver-historial{

}

</style>
